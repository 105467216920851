@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");

:root {

	--primary: #004D99; 
	--secondary: #fff;   
	--link-background: #28a745; 
	--deg: -86deg;
	--trans: all 0.4s ease 0s;
}

/* body {
	background-image: url('../../../Assets/doc1.jpg');
	background-size: cover; 
	background-attachment: fixed;
	margin: 0;
	padding: 0;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
} */

.Dev-content {
    /* width: 90%; */
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    overflow: hidden; /* Ensure content doesn't overflow */
    box-sizing: border-box;
	border: 1px solid rgba(255, 255, 255, 0.25);
	border-radius: 20px;
	box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
}
.Dev-content h1{
	text-align: center;
	color: #305b9b;
}
h2 {
	text-align: center;
	color: var(--primary);
}

.team {
	padding: 2em 0 2em 2.5em;
	margin: 0;
}

.member {
	margin: 1.5em 0 0.5em;
	padding: 0.73em;
	background: linear-gradient(83deg, var(--primary) 0 97%, #fff0 calc(97% + 1px) 100%);
	position: relative;
	list-style: none;
	display: inline-block;
	transform: scale(0.85);
	transition: var(--trans);
	width: 100%;
	max-width: 100%; /* Ensure members don't overflow the container */
	box-sizing: border-box;
}
.github-link {
	display: inline-block;
	margin-top: 0.5em;
	background-color: var(--link-background); /* Green background */
	color: var(--secondary); /* White text */
	padding: 0.1em 0.5em;
	border-radius: 0.5em;
	text-decoration: none;
	transition: var(--trans);
	font-weight: bold;
  }
  
  .github-link:hover {
	background-color: var(--secondary); /* White background on hover */
	color: var(--primary); /* Dark blue text on hover */
  }
  

.member:nth-of-type(even) {
	text-align: right;
	background: linear-gradient(-83deg, var(--primary) 0 97%, #fff0 calc(97% + 1px) 100%);
}

.thumb {
	width: 35vmin;
	height: 35vmin;
	float: left;
	margin-right: 1.25em;
	background: linear-gradient(var(--deg), var(--primary) 0 70%, var(--secondary) 0% 100%);
	transform: rotate(-4deg);
	transition: var(--trans);
	border-radius: 0.25em;
	overflow: hidden;
	margin-left: -3em;
	padding: 0.5em;
}

.member:nth-of-type(even) .thumb {
	--deg: 86deg;
	float: right;
	margin-left: 2em;
	margin-right: -3em;
	transform: rotate(4deg);
}

.thumb img {
	width: 100%;
	height: 100%;
	border-radius: 0.25em;
	filter: grayscale(1);
	background: var(--primary);
	object-fit: cover; /* Ensure image fits properly without distortion */
}

.member:hover {
	transform: scale(1);
	transition: var(--trans);
	filter: drop-shadow(0px 20px 10px #0008);
}

.member:hover .thumb {
	padding: 0.1em;
	transition: var(--trans);
	transform: rotate(-1deg);
	--deg: -89deg;
}

.member:nth-of-type(even):hover .thumb {
	--deg: 91deg;
}

.member:hover .thumb img {
	filter: none;
	transition: var(--trans);
}

.description {
	padding-top: 6vmin;
	text-align:start;
}
/* .description h3{
	text-align: left;
} */
.description p {
	padding: 0 2em;
	margin-bottom: 1em;
	color: var(--secondary); /* Text is white */
	font-size: 1.2em;
}
/* Read more styling */
.read-more-btn {
    color: var(--link-background); /* Use the same green as links */
    cursor: pointer;
    font-weight: 300;
    transition: var(--trans);
	font-size: 20px;
}

.read-more-btn:hover {
    color: green; /* Change to primary color on hover */
}
.description h3 {
	background: var(--primary); /* Dark blue background to make names stand out */
	color: var(--secondary); /* White text color for names */
	display: inline;
	transform: rotate(-2deg);
	position: absolute;
	margin: 0;
	margin-top: -2.25em;
	left: 9vmin;
	padding: 0.5em 0.75em;
	border-radius: 0.25em;
	font-size: 2.0em;
	transform-origin: left bottom;
}

.member:nth-of-type(even) h3 {
	left: inherit;
	right: 9vmin;
	transform: rotate(2deg);
	transform-origin: right bottom;
}

.member:hover h3 {
	transition: var(--trans);
	transform: rotate(0deg);
}

.role {
	font-weight: bold;
	color: var(--primary); /* Same dark blue color */
	margin-top: 0.5em;
	display: inline-block;
	
}

.co-funder:after {
	content: "CO-FOUNDER";
	font-size: 0.75em;
	position: absolute;
	top: -1.5em;
	background: var(--secondary);
	right: 4em;
	transform: rotate(3deg);
	padding: 0.35em 0.75em 0.5em;
	border-radius: 0.25em;
	color: var(--primary);
	font-weight: bold;
}

.co-funder:nth-of-type(even):after {
	right: inherit;
	left: 4em;
	transform: rotate(-3deg);
}

.description p {
    padding: 0 2em;
    margin-bottom: 1em;
    color: var(--secondary); /* Text is white */
    font-size: 1.2em;
    word-wrap: break-word; /* Ensure long words break properly */
    white-space: normal; /* Ensure text wraps to the next line */
    overflow-wrap: break-word; /* Break words if they are too long */
}
.description p a {
	display: inline-block;
	margin: 0.5em 0 0 0;
	background: var(--link-background); /* Green background for links */
	color: var(--secondary); /* White link color */
	padding: 0.1em 0.5em 0.35em;
	border-radius: 0.5em;
	text-decoration: none;
	transition: var(--trans);
}

.description p a:hover {
	transition: var(--trans);
	color: var(--primary); /* Dark blue text on hover */
	background: var(--secondary); /* White background on hover */
	font-weight: bold;
}

/* --- Media Queries for Small Devices --- */
@media (max-width: 768px) {
	.Dev-content {
		width: 95%; /* Make the content wider for smaller screens */
	}

	.member {
		transform: scale(1);
	}

	.thumb {
		width: 30vmin;
		height: 30vmin;
		margin-left: 0;
		margin-right: 0;
	}

	.description {
		padding: 1em 0; 
		font-size: 1.2em;

	}

	h3 {
		position: static; /* Make the name and role stack vertically */
		transform: none; /* Remove rotation */
		margin-bottom: 0.5em;
	}

	.co-funder:after {
		top: 1em; /* Ensure CO-FOUNDER appears below the name */
		position: static; /* Stack it below the name */
		transform: none;
		margin-bottom: 1em;
	}
}

@media (max-width: 480px) {
	.thumb {
		width: 50vmin; /* Make thumb even bigger for small devices */
		height: 40vmin;
	}

	.description h3  {
		font-size: 1.1em; /* Adjust font size for smaller screens */
	}

	.co-funder:after {
		font-size: 0.85em; /* Adjust the CO-FOUNDER size for smaller screens */
	}
}
