/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set Poppins as the default font for the entire site */
body {
  font-family: 'Poppins', sans-serif;
  background-color: #F0F8FF; /* Light background color */
  color: #2f313a; /* Dark grey text for readability */
  line-height: 1.6;
}

/* Consistent font-size and color for headers */
h1, h2, h3, h4, h5, h6 {
  margin: 20px 0;
  font-weight: bold;
  color: #004D99; /* Use a strong dark blue for headers */
}

p {
  font-size: 1.1rem;
  margin-bottom: 15px;
}

/* Links with consistent styles */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
}

/* Flexbox for layout consistency */
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  margin: 0 auto; /* Center the content */
  padding: 20px;
}
/* .content-home{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  min-height: 100vh;
} */

/* Ensure no text overflows the width of the page */
.container {
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
}

/* Footer and header styling */
header, footer {
  background-color: #004D99;
  color:#5CD1E5;
  padding: 20px;
  text-align: center;
  width: 100%;
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  font-size: 1rem;
}

/* Media Queries for responsive design */
@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1rem;
  }

  .content {
    padding: 15px;
    margin: 0;
    max-width: 100%;
  }
}
