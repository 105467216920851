/* Import Poppins font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set Poppins as the default font */
body {
  font-family: 'Poppins', sans-serif;
  /* background-color: #f4f7f6;
  color: #2f313a; */
  line-height: 1.6;
  background-image:url('./Assets/doc6.jpg') ;
  backdrop-filter: blur(1px);


}

/* Typography */
/* h1, h2, h3, h4, h5, h6 {
  color: #004D99;
  margin: 20px 0;
  font-weight: bold;
} */

/* p {
  font-size: 1.1rem;
  margin-bottom: 15px;
} */

/* Links */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
}

/* General container and content settings */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;

}
/* }
.content-home{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0 auto;
} */
.container {
  margin: 0 auto;
  padding: 20px;
  text-wrap:pretty;
}

/* Footer and Header */
header, footer {
  background-color: #004D99;
  color: #5CD1E5;
  padding: 20px;
  text-align: center;
}

footer {
  padding: 10px 20px;
  font-size: 1rem;
}

/* Lists */
ul {
  padding-left: 20px;
  
}

li {
  margin-bottom: 2px;
  line-height: 1.7;
}
/* li {
  display: inline-block;
} */


/* Media Queries for smaller screens */
@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1rem;
  }

  .content {
    padding: 15px;
  }

  li {
    font-size: 1rem;
    margin-bottom: 8px;
  }
}
