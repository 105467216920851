/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #001F3F; /* Dark Blue */
  color: #fff;
  position: relative;
  z-index: 1000;
  flex-wrap: nowrap; /* Prevents items from wrapping to the next line */
}
.navbar-logo:hover{
  color: #62a5e9;
}
/* Logo styling */
.navbar-logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  flex-grow: 1; /* Pushes the nav items to the right */
}

.flask-icon {
  font-size: 1.4rem;
  color: #fff;
  transform: rotate(45deg);
  padding-right: 10px;
  margin-left: 8px;
}

span {
  font-size: 1.5rem;
  color: #C2FACD;
  font-weight: 400;
}

/* List style reset */
li {
  list-style-type: none;
  white-space: nowrap; /* Prevents the list items from wrapping */
}
.navbar button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  padding: 12px 20px;
  text-align: left;
  text-decoration: none;
  transition: all 0.3s ease;
}
.navbar button:hover {
  color: #62a5e9;
  transform: translateY(-2px);
}
button i {
  margin-right: 10px;
}
/* Hamburger menu (for mobile) */
.hamburger {
  background: transparent;
  border: 2px solid #fff;
  padding: 10px;
  width: 52px;
  border-radius: 5px;
  display: none;
  position: absolute;
  right: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
.hamburger:hover {
  border-color: #62a5e9;
}
.hamburger.open .line:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}

.hamburger.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger.open .line:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

.line {
  background: #fff;
  width: 1.5rem;
  height: 0.2rem;
  border-radius: 5px;
  transition: all 0.3s ease;
}

/* Desktop Navigation */
.desktop-nav {
  display: flex;
  align-items: center;

}

.nav-item {
  padding: 12px 20px;
  color: #fff;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  white-space: nowrap; /* Prevents text from wrapping */
}

.nav-item a {
  text-decoration: none;
  color: white;
  white-space: nowrap; /* Prevents text inside links from wrapping */
}


/* Dropdown menu styling */
.dropdown-menu {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #001F3F;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 150px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 0;
  /* visibility: hidden; */
  transform: translateY(-10px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-menu li {
  padding: 10px;
  white-space: nowrap;
}

/* .dropdown-menu li:hover {
  background-color: #62a5e9;
} */

.dropdown.active .dropdown-menu {
  display: block;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-menu {
  opacity: 0;
  transform: translateY(-10px);
}

/* Mobile Navigation */
.mobile-nav {
  display: none;
  flex-direction: column;
  background-color: #001F3F;
  list-style: none;
  padding: 0;
  position: absolute;
  top: 56px;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
}
.navbar button{
  row-gap: -10px;
}

.mobile-nav.active {
  display: flex;
  transform: translateX(0);
}

.nav-item.dropdown .dropdown-menu {
  display: none;
}

/* Active dropdown for mobile */
.dropdown.active .dropdown-menu {
  display: block;
  width: 80%;
}
.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.nav-item:nth-child(1) { animation-delay: 0.1s; }
.nav-item:nth-child(2) { animation-delay: 0.2s; }
.nav-item:nth-child(3) { animation-delay: 0.3s; }
.nav-item:nth-child(4) { animation-delay: 0.4s; }

@media (max-width: 1024px) {
  .desktop-nav {
    display: none;
  }
  
  .hamburger {
    display: flex;
  }
  
  .mobile-nav.active {
    transform: translateX(0);
  }
  
  .dropdown-menu {
    position: relative;
    width: 100%;
  }
  
  .nav-item {
    padding: 15px;
    text-align: left;
  }
}

/* Responsive styles for mobile */
@media (max-width: 1024px) {
  .desktop-nav {
      display: none;
  }

  .hamburger {
      display: flex;
  }

  .mobile-nav.active {
    transform: translateX(0);
  }

  .dropdown-menu {
    position: relative;
    width: 100%; /* Expand dropdown to full width */
  }

  .nav-item {
    padding: 15px;
    text-align: left;
  }
}