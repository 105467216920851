.access-page {
    margin: 0 auto;
    width: 100%;
    padding: 40px 20px;
    text-align: center;
    border-radius: 10px;
  
}

.access-container {
    margin: 0 auto;
    width: 100%;
    padding: 40px 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
}

.access-container h1 {
  font-size: 2.5rem;
  color: #004d99; /* Dark Blue (Primary color) */
}

.access-container p {
  font-size: 1.2rem;
  color: #2f313a; /* Dark Grey */
}

.pdf-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Added padding for better spacing */
}

.pdf-section {
  width: 100%;
  height: 100%;
}

.pdf-iframe {
  width: 100%;
  height: 100vh;
  border: none;
}

.access-container button {
  padding: 10px 20px;
  background-color: #28a745; /* Green */
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  padding: 10px;
}

.access-container button:hover {
  background-color: #218838;
}

.access-container button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Media Queries */
@media (max-width: 768px) {
  .pdf-section iframe {
    height: 400px;
  }

  button {
    padding: 15px;
  }
}
