.footer {
  background-color: #001F3F; /* Dark Blue */
  color: #fff;
  text-align: center;
  border-top: 1px solid #3aafa9; /* Light Teal */
  font-size: 18px;
  padding: 20px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Space between contact items */
}

.contact i {
  margin-right: 8px;
  font-size: 1.2rem;
}

/* Styling for the button links */
.link-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
  text-align: left;
}
.footer .link-button:hover {
  color: #5CD1E5;
}

.footflask {
  font-size: 1.5rem;
  color: #fff;
  transform: rotate(45deg);
  padding-right: 8px;
}

.spanfoot {
  font-size: 1.5em;
}

/* Popup Modal Styling */
.footer-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay */
  display: flex;
  align-items: center;
  justify-content:center;
  z-index: 9999;
}

.footer-popup {
  background-color: #ffffff;
  padding: 50px;
  border-radius: 10px;
  text-align: center;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border: 1px solid #004D99; /* Dark blue border */
}

.footer-popup h3 {
  margin-bottom: 10px;
  color: #004D99; /* Dark blue title */
}

.popup-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popup-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  text-align: left;
  align-items: center;
}

.call-btn {
  background-color: #004D99; /* Dark Blue for call */
}

.whatsapp-btn {
  background-color: #3aafa9; /* Light teal for WhatsApp */
}

.close-btn {
  background-color: #dc3545; /* Red for cancel */
}

.popup-btn:hover {
  opacity: 0.9;
  color : white;
}
/* Form-specific styles inside the popup */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.form-group label {
  margin-bottom: 5px;
  color: #004D99; /* Dark Blue */
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.send-btn {
  background-color: #004D99; /* Dark Blue */
}

.send-btn:hover {
  background-color: #003366;
}
.map-btn {
  background-color: #004D99; /* Dark Blue for Google Maps */
}

.web-btn {
  background-color: #3aafa9; /* Light teal for web search */
}

.popup-btn:hover {
  opacity: 0.9;
}


/* Media Queries */
@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .footer-section-about {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .contact {
    flex-direction: row;
    justify-content: center;
  }

  .contact a {
    margin-right: 20px; /* Add space between each contact link */
  }
}

@media (max-width: 768px) {
  .footer {
    font-size: 16px;
  }

  .footer-content {
    flex-direction: column;
    align-items: left;
  }

  .contact {
    flex-direction: column;
    align-items: left;
  }

  .contact a {
    display: flex;
    align-items: left;
    margin: 10px 0;
  }

  .popup {
    max-width: 90%; /* Ensure popup fits on smaller screens */
  }
  .contact button.link-button{
    margin: 0;
  }
  .link-button{
    padding: 5px;
    width: 305px;
  }
}

