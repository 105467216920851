.organic-page {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f8f9fa; /* Light grey background */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    line-height: 1.6;
  }
  
  .organic-head {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .organic-head h1 {
    font-size: 2.5rem;
    font-weight: 350;
    color: #004D99; /* Dark Blue color for title */
  }
  
  .organic-content h2 {
    font-size: 1.8rem;
    color: #0066cc; /* Medium Blue for headings */
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 300;
  }
  
  .organic-content p {
    font-size: 1.1rem;
    margin-bottom: 15px;
    color: #333; /* Darker text color for readability */
  }
  
  .organic-content b {
    font-weight: 400;
    color: #004D99; /* Bold Blue for emphasis */
  }
  
  hr {
    border: 0;
    height: 1px;
    background: #ddd;
    margin: 20px 0;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .organic-head h1 {
      font-size: 2rem;
    }
  
    .organic-content h2 {
      font-size: 1.5rem;
    }
  
    .organic-content p {
      font-size: 1rem;
    }
  
    .organic-page {
      padding: 20px 15px;
    }
  }
  
  @media (max-width: 480px) {
    .organic-head h1 {
      font-size: 1.8rem;
    }
  
    .organic-content h2 {
      font-size: 1.3rem;
    }
  
    .organic-content p {
      font-size: 0.95rem;
    }
  }
  