.notfound-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem 1.5rem;
	min-height: 100vh;
  }
  
  .notfound-image {
	margin-bottom: 1.25rem;
	max-width: 100%;
  }
  
  .notfound-text {
	text-align: center;
	max-width: 40rem;
  }
  
  .notfound-text h1 {
	margin-bottom: 0.75rem;
	font-size: 2rem;
	font-weight: bold;
	color: #38a169;
  }
  
  .notfound-text p {
	margin-bottom: 1.25rem;
	font-size: 1rem;
	color: #4a5568;
  }
  
  .notfound-button {
	display: inline-flex;
	align-items: center;
	padding: 0.625rem 1.25rem;
	font-size: 0.875rem;
	font-weight: 500;
	color: white;
	background-color: #2d3748;
	border-radius: 0.375rem;
	text-decoration: none;
  }
  
  .notfound-button:hover {
	background-color: #1a202c;
	color: white;
  }
  
  .notfound-icon {
	margin-right: 0.5rem;
	width: 1.25rem;
	height: 1.25rem;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
	.notfound-text h1 {
	  font-size: 1.75rem;
	}
  
	.notfound-text p {
	  font-size: 0.875rem;
	}
  
	.notfound-button {
	  padding: 0.5rem 1rem;
	  font-size: 0.75rem;
	}
  
	.notfound-icon {
	  width: 1rem;
	  height: 1rem;
	}
  }
  
  @media (max-width: 480px) {
	.notfound-text h1 {
	  font-size: 1.5rem;
	}
  
	.notfound-text p {
	  font-size: 0.75rem;
	}
  
	.notfound-button {
	  padding: 0.375rem 0.75rem;
	  font-size: 0.625rem;
	}
  
	.notfound-icon {
	  width: 0.875rem;
	  height: 0.875rem;
	}
  }