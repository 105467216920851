.home {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
   
}
.home-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5%;
    margin-top: 0px;
}
.home-content h2{
    font-weight: 500;
    text-align: center;
    margin-top: 0px
}
.card {
    width: 500px;
    display: flex;
    flex-direction: column;
    padding: 50px 50px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 20px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);

}
.card h1{
    text-align: center;
}
.card img{
    width: 200px;
    margin: auto;
    border-radius: 20%;
    box-shadow:0 0 18px 15px rgba(0,0,0,0.25);
}


.telegram-link {
    margin-top: 20px;
    cursor: pointer;
    color: #1e5a95;
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;
    transition: color 0.3s ease;
}

.telegram-link:hover {
    color: #39CCCC;  /* Professional teal hover effect */
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 50%;
    background: rgba(0, 0, 0, 0.6); /* Dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.popup {
    background-color: #ffffff;
    color:#1e5a95;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-top: 1px solid #3aafa9; /* Light teal border */
    margin-bottom: 50%;

}
.popup-buttons .popup-btn-cancel{
    background-color: red;
}
.popup h3 {
    margin-bottom: 20px;
    color: #3aafa9; /* Light teal title */
}

.popup-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.popup-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;  /* Align the icon and text */
    justify-content: center;
    gap: 10px;  /* Space between the icon and the text */
}

.popup-btn i {
    font-size: 1.2rem;  /* Adjust the icon size */
}

.popup-btn:hover {
    transform: scale(1.05);  /* Scale button slightly */
}

.call-btn {
    background-color: #004D99; /* Dark blue for Telegram */
}

.github-btn {
    background-color: #333; /* Dark grey for GitHub */
}

.close-btn {
    background-color: #dc3545; /* Red for cancel */
}

.input-container {
    position: relative;
    width: 100%;
  }
  
  .input {
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    outline: none;
  }
  
  .placeholder {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    color: #929090;
    transition: all 0.3s ease;
    pointer-events: none;
  }
  
  .input:focus + .placeholder,
  .active {
    top: -10px;
    font-size: 0.7rem;
    color: #1e5a95; /* Change color to indicate focus */
  }
  
  .input:focus {
    border-color: #1e5a95; /* Border color changes on focus */
  }
.home button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(90deg, #004D99 0%, #1e5a95 100%);
    color: white;
    cursor: pointer;
    font-size: 1rem;
    text-align:center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.home button:hover {
    background-color: #39CCCC; /* Teal hover effect */
    transform: scale(1.05);
}

.home button:active {
    background-color: #1e5a95;
}

.home button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.compound-result {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
}

.error {
    color: red;
    margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .home {
        font-size: 0.95rem;
    }

    .card {
        max-width: 100%;
        height: auto;
        padding: 20px;
        display: flex;
    }

    .content {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .card {
        position: static;
    }

    .home {
        font-size: 0.9rem;
    }

    .card {
        max-width: 100%;
        height: auto;
        padding: 20px;
    }

    .content {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .home {
        font-size: 0.8rem;
        padding: 10px;
    }

    .card {
        padding: 15px;
    }
}
@media(max-width:386px){
    .card {
        padding: 10px;
    }
}
@media (max-width: 320px) {
    .home {
        font-size: 0.7rem;
        padding: 5px;
    }

    .card {
        max-width: 100%;
        padding: 20px;
    }
}
