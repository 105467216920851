.iupac-container {
    margin: 0 auto;
    width: 100%;
    padding: 40px 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  }

  .iupac-container h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #004D99; /* Primary color */
  }
  
  .iupac-container p {
    font-size: 1.2rem;
    color: #2f313a;
  }
  
  .pdf-section {
    margin-top: 20px;
  }
  
  .pdf-section h2 {
    font-size: 2rem;
    color: #004D99; /* Primary color */
  }
  
  .pdf-section iframe {
    width: 100%;
    height: 600px;
    border: 2px solid #004D99;
    margin-bottom: 20px;
  }
  
  .download-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #28a745; /* Green background for the button */
    color: #fff;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .download-link:hover {
    background-color: #218838; /* Darker green on hover */
  }
  